@media (max-width: 900px) {
  .content {
    flex-direction: column !important;
  }

  .posts {
    max-width: 100% !important;
  }
  .tagsComments {
    max-width: 100% !important;
  }
}
